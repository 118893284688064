/* eslint-disable jsx-a11y/anchor-is-valid, react-hooks/exhaustive-deps */
import React, {Suspense, lazy, useEffect} from "react";
import amplitude, { Config } from "amplitude-js";
import { AmplitudeProvider } from "react-amplitude-hooks";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import * as url from "url";
import { useAnalytics } from "./hooks/useAnalytics";

const EntryPage = lazy(() => import("./pages/entry"));
const ProfilePage = lazy(() => import("./pages/profile"));
const SessionPage = lazy(() => import("./pages/session"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 3
    }
  }
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <HelmetProvider>
        <div className="App">
          <Suspense fallback={null}>
            <Router>
              <Routing />
            </Router>
          </Suspense>
        </div>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

function Routing() {
  const {
    query: { application }
  } = url.parse(window.location.href, true);
  const { initialize } = useAnalytics();
  const location = useLocation();
  useEffect(() => {
    application && initialize();
  }, [application])
  return (
      <div className={`is-app-${application}`}>
    <AmplitudeProvider
      amplitudeInstance={amplitude.getInstance()}
      apiKey={process.env.REACT_APP_AMPLITUDE_API_KEY ?? ""}
      config={
        {
          deviceIdFromUrlParam: true,
          includeFbclid: true,
          includeGclid: true,
          includeUtm: true,
          includeReferrer: true
        } as Config
      }
    >
      <Switch>
        <Route path="/entry" children={<EntryPage />} />
        <Route path="/profile" children={<ProfilePage />} />
        <Route path="/session" children={<SessionPage />} />
        <Redirect to={{ ...location, pathname: `/entry` }} />
      </Switch>
    </AmplitudeProvider>
      </div>
  );
}

export default App;
