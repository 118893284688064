export interface EntryTokens {
  application: string;
  ga: string;
  fbq: string
  ttq: string;
}

export const EntryConfigs = {
  "frecam": {
    application: "3a8b6033811498408cfee867479b8f1c9587a7a6057a0cc60450899940b533e6",
    ga: "UA-52344329-13",
    fbq: "320057338981217",
    ttq: "C1MP5BGB69GLP6V5VFRG",
    entered_in: "2022"
  },
  "misscircle": {
    application: "a57f9b573acb4cff3a2c6ab80c05a3ba0cfda2d8f449b90ec7659c952ee7839c",
    ga: "UA-52344329-14",
    fbq: "576234076334949",
    ttq: "C1MP86MDPLBQ4GPQ7E9G"
  }
}

export class Entry {
  data: { [key: string]: any };
  constructor(props: { [key: string]: any }) {
    this.data = props;
  }
  app(): EntryTokens {
    if(this.data.value['entered_in'] === "2022") {
      return EntryConfigs.frecam;
    } else {
      return EntryConfigs.misscircle;
    }
  }
  toRequest(): { [key: string]: string } {
    return {
      ...this.data.value
    };
  }
}
